@import '@aurora/shared-client/styles/_variables.pcss';

.lia-delete {
  margin-right: auto;

  @media (--lia-breakpoint-down-sm) {
    width: 100%;
  }
}

@media (--lia-breakpoint-down-sm) {
  .lia-inner {
    row-gap: 10px;

    .lia-actions.lia-actions {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
}
.lia-form-action {
  @media (--lia-breakpoint-down-sm) {
    gap: 10px;
  }
}
